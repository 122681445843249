<template>
<div class="main">
    <br>
    <div class="flex-container">
        <b-card class="price-model" v-for="(model, idx) in models" :key="idx"
            v-bind:title="model.title"
            img-alt="Image"
            img-top
            tag="article"
            style="width: 15rem;"
            >
            <b-card-sub-title class="card-subtitle">{{model.description}}</b-card-sub-title>
            <b-card-text class="card-text">
                {{model.costs}}
            </b-card-text>
            <br>
            <b-button href="#" class="buy-btn" variant="danger">Buy</b-button>
        </b-card>
    </div>
</div>
</template>

<script>
export default {
    name: 'Chatbot',
    data(){
        return {
            models:[
                {title: "On-Demand", description:"Choose the concert you would like to hear", costs:"9.99€/concert"},
                {title: "Basic", description:"All concerts in regular quality", costs:"19.99€/month"},
                {title: "Genre-Premium", description:"All concerts in regular and one selected genre in best quality", costs:"29.99€/month"},
                {title: "Premium", description:"All concerts in best quality", costs:"39.99€/month"}]
        }
    }
}
</script>

<style scoped>
.main{
    width: 80%;
    margin: auto;
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.price-model{
    background-color:black;
    color: white;
    border-radius: 10px;
    border: 2px solid #ba9856;
    margin:1em;
}

.card-subtitle{
    color: #ba9856 !important;
    margin: 2em auto;
}

.card-text{
    position: absolute;
    right : 2px;
    bottom: 30px;
    color: white;
}

.buy-btn{
    background-color: #ba9856;
    border-color: #ba9856;
    position:absolute;
    bottom: 4px;
    right: 4px;
}

</style>
