<template>
<div class="main">
        <br>
    <b-carousel
      id="carousel"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
        <b-carousel-slide v-for="(concert, idx) in concerts" :key="idx"
            v-bind:caption="concert.title"
            v-bind:text="concert.artist + ' in '  + concert.location"
            v-bind:img-src="concert.thumbnail">
        </b-carousel-slide>
    </b-carousel>
    <h3 class="sub-header">Vienna</h3>
    <hr class="sub-header-line">
    <center>
    <div class="flex-container">
        <div class="concert-teaser" v-for="(concert, idx) in concerts" :key="idx" >
            <b-card class="card-teaser"
                overlay
                v-bind:img-src="concert.thumbnail"
                v-bind:title="concert.title"
                text-variant="white"
                img-height="200px"
                img-top
                tag="article"
                style="max-width: 20rem;"
            >
            <b-card-text>{{concert.artist + ' in '  + concert.location}}</b-card-text>
            <b-button class="enjoy-btn" href="#" variant="danger">Show</b-button>
            </b-card>
        </div>
    </div>
    </center>
    <br>
</div>
</template>

<script>
export default {
    name: 'Concerts',
    data(){
        return {
            concerts:[
                {title: "Escape The Nest", artist: "Please Madame", length:"1:27h", location: "Vienna", thumbnail:"https://i2.wp.com/blogboheme.de/wp-content/uploads/2016/04/Titelbild-Ludwig-Seidl-Captures.jpg?w=1024&ssl=1"},
                {title: "At Pavillon in Concert", artist: "At Pavillon", length:"1:27h", location: "Vienna", thumbnail:"https://i0.wp.com/blogboheme.de/wp-content/uploads/2016/04/At-Pavillon.jpg?w=1024&ssl=1"},
                {title: "Mynth in Concert", artist: "Mynth", length:"1:27h", location: "Vienna", thumbnail:"https://i0.wp.com/blogboheme.de/wp-content/uploads/2016/04/Mynth.jpg?w=1024&ssl=1"},
                {title: "Jay Cooper in Concert", artist: "Jay Cooper", length:"1:27h", location: "Vienna", thumbnail:"https://i2.wp.com/blogboheme.de/wp-content/uploads/2016/04/Jay-Cooper2.jpg?w=1024&ssl=1"}],
            chatContent: []
        }
    }
}
</script>

<style scoped>
.main{
    width: 80%;
    margin: auto;
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.sub-header{
    margin-top: 1em;
    text-align: left;
}

.sub-header-line{
    width: 2.5em;
    margin-left: 0;
    border: 4px solid #ba9856;
}

.card-teaser{
    width: 15em;
    margin-bottom: 0px;
}

.enjoy-btn{
    background-color: #ba9856;
    border-color: #ba9856;
    position:absolute;
    bottom: 4px;
    right: 4px;
}
</style>
