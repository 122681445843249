<template>
    <nav id="vue">
        <b-navbar class="audiophilex-navbar" toggleable="lg">
            <b-navbar-brand ><router-link style="color: white; font-family: Lucida Console;" to="/Concerts"><span style="font-style: italic; font-weight: bold; color: white;">A</span>udiophile<span style="font-style: italic; font-weight: bold; color:#ba9856;">X</span></router-link></b-navbar-brand>
            <b-navbar-toggle target="nav-collapse" style="background-color: #ba9856;"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
            <b-nav-item ><router-link style="color: #ba9856;" to="/Concerts">Concerts</router-link></b-nav-item>
            <b-nav-item><router-link style="color: #ba9856;" to="/Pricing">Pricing</router-link></b-nav-item>
            <b-nav-item><router-link style="color: #ba9856;" to="/Profile">Profile</router-link></b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </nav>
</template>

<script>
export default {
  name: 'navbar',
  components: {
  }
}
</script>

<style scoped>
.audiophilex-navbar{
    background-color: #000000;
}


</style>