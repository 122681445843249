import Vue from 'vue'
import VueRouter from 'vue-router'
import Concerts from '@/pages/Concerts.vue'
import Pricing from '@/pages/Pricing.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/Concerts',
            name: 'Concerts',
            component: Concerts
        },
        {
            path: '/Pricing',
            name: 'Pricing',
            component: Pricing
        }
    ]
});

export default router
